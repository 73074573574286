const context = {
  gridCols: null,
  gridRows: null,
  tileSize: null,
  zoom: 4,
  zoomLevels: [20, 20, 20, 50, 100],
  camera: null,
  players: null,
  allowContextMenu: false,
};

let hexbin;
let points;

window.toggleContextMenu = function () {
  context.allowContextMenu = !context.allowContextMenu;
  document.getElementById(
    "toggle_context_menu_text"
  ).innerHTML = context.allowContextMenu ? "disable" : "enable";
  drawMap();
};

function toEven(value) {
  return 2 * Math.floor(value / 2);
}

function toOdd(value) {
  return toEven(value) + 1;
}

function getTilePosition({ gridX, gridY }) {
  const { gridCols, gridRows, camera } = context;
  const centerX = Math.floor(gridCols / 2);
  const centerY = Math.floor(gridRows / 2);
  const x = gridX + camera.x - centerX;
  const y = gridY + camera.y - centerY;
  return { x, y };
}

function getHexagonPoints() {
  const { map, players, tileSize, gridCols, gridRows } = context;
  let isFirstRowEven = false;
  const points = [];

  // preview first cell of grid to detect even vs odd
  // not sure if there is a better way to do this,
  // but this seems to work for now
  for (let gridY = 0; gridY < 1; gridY += 1) {
    for (let gridX = 0; gridX < 1; gridX += 1) {
      const { y: tileY } = getTilePosition({
        gridX,
        gridY,
      });
      isFirstRowEven = tileY % 2 === 0;
      break;
    }
    break;
  }

  for (let gridY = 0; gridY < gridRows; gridY += 1) {
    for (let gridX = 0; gridX < gridCols; gridX += 1) {
      let x = tileSize * gridX * Math.sqrt(3);
      if (gridY % 2 === 1) {
        x += (tileSize * Math.sqrt(3)) / 2;
      }

      const y = tileSize * gridY * 1.5;
      const tilePos = getTilePosition({
        gridX,
        gridY,
      });

      const { x: tileX } = tilePos;
      let { y: tileY } = tilePos;

      if (isFirstRowEven) {
        tileY = tileY + 1;
      }

      const height = map[tileY][tileX];

      const player = players.find((item) => {
        return item.x === tileX && item.y === tileY;
      });

      const colorName = getTileHeightColor(height);
      points.push([
        x,
        y,
        {
          tileX,
          tileY,
          colorName,
          player,
        },
      ]);
    }
  }

  return points;
}

function drawMapBackground() {
  const { tileSize } = context;
  const background = d3
    .select("#grid > svg")
    .append("g")
    .selectAll(".hexagon.background")
    .data(hexbin(points))
    .enter()
    .append("path")
    .attr("d", hexbin.hexagon())
    .attr("transform", (d) => `translate(${d.x},${d.y})`);

  background.attr("class", (data) => {
    const [, , { colorName }] = data[0];
    const colorClass = `color-${colorName}`;
    const classes = ["hexagon", "background", colorClass];
    if (tileSize > 10) {
      classes.push("border");
    }
    return classes.join(" ");
  });
}

function getPlayerImageHeight(zoom) {
  switch (zoom) {
    case 2:
      return 30;
    case 3:
      return 70;
    case 4:
    default:
      return 140;
  }
}

function getPlayerImageWidth(zoom) {
  return getPlayerImageHeight(zoom);
}

function getPlayerImageCoordsX(zoom) {
  switch (zoom) {
    case 2:
      return 2;
    case 3:
      return 8;
    case 4:
      return 15;
    default:
      return 0;
  }
}

function getPlayerImageCoordsY(zoom) {
  switch (zoom) {
    case 2:
      return 5;
    case 3:
      return 10;
    case 4:
      return 20;
    default:
      return 0;
  }
}

function drawMapSvgDefines() {
  const { zoom } = context;
  const svg = d3.select("#grid > svg");
  const defs = svg.append("svg:defs");
  defs
    .append("pattern")
    .attr("id", `hero-${zoom}`)
    .attr("width", 1)
    .attr("height", 1)
    .append("image")
    .attr("href", `/sprites/hero.png`)
    .attr("width", getPlayerImageWidth(zoom))
    .attr("height", getPlayerImageHeight(zoom))
    .attr("x", getPlayerImageCoordsX(zoom))
    .attr("y", getPlayerImageCoordsY(zoom));
}

function drawMapCoordsText() {
  const { tileSize, current } = context;
  d3.select("#grid > svg")
    .selectAll(".hexagon.coords")
    .data(hexbin(points))
    .enter()
    .append("text")
    .filter((data) => {
      const [, , { tileX, tileY }] = data[0];
      if (!current) {
        return false;
      }
      const { x, y } = current;
      return inPositionRange({ x, y }, { tileX, tileY });
    })
    .attr("class", (data) => {
      const [, , { colorName }] = data[0];
      const colorClass = `color-${colorName}`;
      const classes = ["coords-text", colorClass];
      return classes.join(" ");
    })
    .text((data) => {
      const [, , props] = data[0];
      const { tileX, tileY } = props;
      return `${tileX},${tileY}`;
    })
    .attr("x", (data) => {
      const [x] = data[0];
      return x;
    })
    .attr("y", (data) => {
      const [, y] = data[0];
      return y + tileSize - 30;
    })
    .attr("text-anchor", "middle")
    .style("font-size", () => {
      if (tileSize > 50) {
        return "16px";
      }
      return "14px";
    });
}

function isPosition({ x, y }, { tileX, tileY }) {
  if (x === tileX && y === tileY) {
    return true;
  }
  return false;
}

function inPositionRange({ x, y }, { tileX, tileY }) {
  if (isPosition({ x, y }, { tileX, tileY })) {
    return false;
  }

  const isEvenRow = y % 2 === 0;
  // 1 - directly to the left
  // 2,3,4,5 - clockwise

  // 1
  if (
    (isEvenRow && x - 1 === tileX && y === tileY) ||
    (!isEvenRow && x - 1 === tileX && y === tileY)
  ) {
    return true;
  }

  //2
  if (
    (isEvenRow && x === tileX && y - 1 === tileY) ||
    (!isEvenRow && x - 1 === tileX && y - 1 === tileY)
  ) {
    return true;
  }

  //3
  if (
    (isEvenRow && x + 1 === tileX && y - 1 === tileY) ||
    (!isEvenRow && x === tileX && y - 1 === tileY)
  ) {
    return true;
  }

  // 4
  if (x + 1 === tileX && y === tileY) {
    return true;
  }

  //5
  if (
    (isEvenRow && x + 1 === tileX && y + 1 === tileY) ||
    (!isEvenRow && x === tileX && y + 1 === tileY)
  ) {
    return true;
  }

  //5
  if (
    (isEvenRow && x === tileX && y + 1 === tileY) ||
    (!isEvenRow && x - 1 === tileX && y + 1 === tileY)
  ) {
    return true;
  }

  return false;
}

function isMoveAllowed({ x, y }) {
  const { map } = context;
  const height = map[y][x];
  const colorName = getTileHeightColor(height);
  switch (colorName) {
    case "water":
    case "deepWater":
    case "mountain":
    case "highMountain":
    case "snow":
      return false;
    default:
      return true;
  }
}

function doCurrentPlayerSafeMove({ x, y }) {
  const { map, players } = context;
  const allowed = isMoveAllowed({ x, y });
  if (!allowed) {
    // todo: do we inform the user somehow that this move can't be done?
    return;
  }
  context.players = players.map((player) => {
    if (player.current) {
      return Object.assign(player, { x, y });
    }
    return player;
  });
  context.camera = { x, y };
  drawMap();
}

function drawMapSprites() {
  const { zoom, tileSize, current, allowContextMenu } = context;
  const sprites = d3
    .select("#grid > svg")
    .append("g")
    .selectAll(".hexagon.sprite")
    .data(hexbin(points))
    .enter()
    .append("path")
    .attr("d", (d) => {
      return `M${d.x},${d.y}${hexbin.hexagon()}`;
    });

  sprites.attr("fill", (data) => {
    const [, , { player, tileX, tileY }] = data[0];
    if (!current) {
      return;
    }
    const { x, y } = current;
    if (isPosition({ x, y }, { tileX, tileY })) {
      if (zoom > 1) {
        return `url(#${current.name}-${zoom})`;
      }
      return "yellow";
    }
    return "transparent";
  });

  sprites.attr("class", (data) => {
    const [, , { tileSize, colorName, tileX, tileY }] = data[0];
    const colorClass = `color-${colorName}`;
    const classes = ["hexagon", colorClass];
    if (tileSize >= 20) {
      classes.push("border");
    }
    classes.push(`x-${tileX}`);
    classes.push(`x-${tileY}`);
    if (current) {
      const { x, y } = current;
      if (isPosition({ x, y }, { tileX, tileY })) {
        classes.push(`sprite current-player ${current.name}`);
      }
      if (inPositionRange({ x, y }, { tileX, tileY })) {
        classes.push("sprite fa-fade valid-move");
      }
    }
    return classes.join(" ");
  });

  const adjacent = sprites.filter((data) => {
    const [, , { tileX, tileY }] = data[0];
    if (!current) {
      return false;
    }
    const { x, y } = current;
    return inPositionRange({ x, y }, { tileX, tileY });
  });

  adjacent.on("click", (_event, data) => {
    const [, , { tileX, tileY }] = data[0];
    event.preventDefault();
    doCurrentPlayerSafeMove({
      x: tileX,
      y: tileY,
    });
  });

  sprites.on("contextmenu", (event, data) => {
    const [, , { tileX, tileY }] = data[0];

    // to see browser context menu
    if (!allowContextMenu) {
      event.preventDefault();
    }

    doCurrentPlayerSafeMove({
      x: tileX,
      y: tileY,
    });
  });

  if (tileSize > 20) {
    drawMapCoordsText();
  }
}

function getTileColors() {
  return {
    deepWater: "#003eb2",
    water: "#356dc6",
    sand: "#c2b280",
    dirt: "#a49463",
    grass: "#557a2d",
    forest: "#3c6114",
    darkForest: "#284d00",
    mountain: "#8c8e7b",
    highMountain: "#a0a28f",
    snow: "#ffffff",
  };
}

function getMinimapInfoText() {
  const { camera, current } = context;
  return [
    `Camera: ${camera.x} ${camera.y}`,
    `Player: ${current.x} ${current.y}`,
  ].join(", ");
}

function drawMinimap() {
  const { map, camera, gridCols, gridRows } = context;
  const step = 5;
  const rows = [];

  function getImageBoxCss() {
    const el = document.getElementById("minimap-wrapper");
    const miniMapWidth = el.getBoundingClientRect().width;
    const miniMapHeight = el.getBoundingClientRect().height;
    const ratioX = miniMapWidth / map.length;
    const ratioY = miniMapHeight / map.length;
    const left = Math.floor(camera.x * ratioX);
    const top = Math.floor(camera.y * ratioY);
    const width = gridCols * ratioX;
    const height = gridRows * ratioY;
    return {
      left: `${left}px`,
      top: `${top}px`,
      width: `${width}px`,
      height: `${height}px`,
      display: "block",
    };
  }

  const canvas = document.getElementById("minimap");
  const ctx = canvas.getContext("2d");
  const tileColors = getTileColors();

  // create a smaller map by stepping over tiles
  for (let y = 0; y < map.length; y = y + step) {
    let row = [];
    for (let x = 0; x < map[y].length; x = x + step) {
      const height = map[y][x];
      row.push(height);
    }
    rows.push(row);
  }

  // render the smaller map for each pixel
  for (let y = 0; y < rows.length; y = y + 1) {
    for (let x = 0; x < rows[y].length; x = x + 1) {
      const height = rows[x][y];
      const colorName = getTileHeightColor(height);
      const hexColor = tileColors[colorName];
      ctx.fillStyle = hexColor;
      ctx.fillRect(y, x, 1, 1);
    }
  }

  document.getElementById(
    "minimap-location-box"
  ).style.cssText = Object.entries(getImageBoxCss())
    .map(([k, v]) => `${k}:${v}`)
    .join(";");

  document.getElementById("minimap-info").innerHTML = getMinimapInfoText();
}

function timeout(ms = 0) {
  return new Promise((resolve) => {
    setTimeout(() => requestAnimationFrame(resolve), ms);
  });
}

function createSvg() {
  d3.select("#grid").append("svg");
}

function teardownSvg() {
  d3.select("#grid > svg").remove();
}

async function drawMap(gridChange = false) {
  updateGridSize();
  await timeout();
  const { tileSize } = context;

  // if (gridChange) {
  hexbin = d3.hexbin().radius(tileSize);
  points = getHexagonPoints();
  // }
  teardownSvg();
  createSvg();
  drawMapSvgDefines();
  drawMapBackground();
  drawMapSprites();
  drawMinimap();
}

function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

function getGridCols(gridSize) {
  const { tileSize } = context;
  const fitScreenFactor = 1.73;
  return toOdd(Math.ceil(gridSize[0] / (tileSize * fitScreenFactor)));
}

function getGridRows(gridSize) {
  const { tileSize } = context;
  const fitScreenFactor = 1.49;
  return toOdd(Math.ceil(gridSize[1] / (tileSize * fitScreenFactor)));
}

function updateGridSize() {
  const { tileSize } = context;
  const el = document.getElementById("grid");
  const { width, height } = el.getBoundingClientRect();
  const gridSize = [Math.ceil(width), Math.ceil(height)];
  context.tileSize = context.zoomLevels[context.zoom];
  context.gridCols = getGridCols(gridSize);
  context.gridRows = getGridRows(gridSize);
  context.current = context.players.find((player) => player.current);
}

function getTileHeightColor(height) {
  if (height >= 3) {
    return "red";
  }
  if (height >= 2) {
    return "orange";
  }
  if (height >= 1) {
    return "yellow";
  }
  if (height < 0.39) {
    return "deepWater";
  }
  if (height < 0.42) {
    return "water";
  }
  if (height < 0.46) {
    return "sand";
  }
  if (height < 0.47) {
    return "dirt";
  }
  if (height < 0.54) {
    return "grass";
  }
  if (height < 0.55) {
    return "forest";
  }
  if (height < 0.68) {
    return "darkForest";
  }

  // mountains and above
  if (height >= 0.79) {
    return "snow";
  }
  if (height >= 0.74) {
    return "highMountain";
  }
  if (height >= 0.68) {
    return "mountain";
  }
}

async function getMap(mapFile) {
  const resp = await (await fetch(`/maps/${mapFile}.csv`)).text();
  const map = resp
    .split("\n")
    .filter(Boolean)
    .map((item) => item.split(",").filter(Boolean).map(parseFloat));
  return map;
}

function setZoomIn() {
  const { zoom, zoomLevels } = context;
  context.zoom = Math.min(zoomLevels.length - 1, zoom + 1);
  context.tileSize = context.zoomLevels[context.zoom];
  drawMap(true);
}

function setZoomOut() {
  const { zoom } = context;
  context.zoom = Math.max(0, context.zoom - 1);
  context.tileSize = context.zoomLevels[context.zoom];
  drawMap(true);
}

function getAdjacentPostition({ index, x, y }) {
  const isEvenRow = y % 2 === 0;
  let newPos = {
    x,
    y,
  };
  switch (index) {
    // up + left
    case 0:
      if (isEvenRow) {
        newPos = { x, y: y - 1 };
      } else {
        newPos = { x: x - 1, y: y - 1 };
      }
      break;

    // up + right
    case 1:
      if (isEvenRow) {
        newPos = { x: x + 1, y: y - 1 };
      } else {
        newPos = { x, y: y - 1 };
      }
      break;

    // left
    case 2:
      newPos = { x: x - 1, y };
      break;
    // right
    case 3:
      newPos = { x: x + 1, y };
      break;

    // down + left
    case 4:
      if (isEvenRow) {
        newPos = { x, y: y + 1 };
      } else {
        newPos = { x: x - 1, y: y + 1 };
      }
      break;

    // down + right
    case 5:
      if (isEvenRow) {
        newPos = { x: x + 1, y: y + 1 };
      } else {
        newPos = { x, y: y + 1 };
      }

      break;
    default:
      break;
  }

  return newPos;
}

function getKeypressAdjacentPostition({ key, x, y }) {
  // top left
  if (String(key).toLowerCase() === "q") {
    return getAdjacentPostition({ index: 0, x, y });
  }

  // top right
  if (String(key).toLowerCase() === "e") {
    return getAdjacentPostition({ index: 1, x, y });
  }

  // middle left
  if (String(key).toLowerCase() === "a") {
    return getAdjacentPostition({ index: 2, x, y });
  }

  // middle right
  if (String(key).toLowerCase() === "d") {
    return getAdjacentPostition({ index: 3, x, y });
  }

  // bottom left
  if (String(key).toLowerCase() === "z") {
    return getAdjacentPostition({ index: 4, x, y });
  }

  // bottom right
  if (String(key).toLowerCase() === "c") {
    return getAdjacentPostition({ index: 5, x, y });
  }

  return { x, y };
}

function onKeypress({ key, code, repeat }) {
  const { current } = context;
  if (repeat) {
    return;
  }
  const { x, y } = current;

  if (["NumpadAdd", "Equal"].includes(code)) {
    setZoomIn();
    return;
  }

  if (["NumpadSubtract", "Minus"].includes(code)) {
    setZoomOut();
    return;
  }

  if (["q", "e", "a", "d", "z", "c"].includes(key.toLowerCase())) {
    const targetPos = getKeypressAdjacentPostition({ key, x, y });
    doCurrentPlayerSafeMove(targetPos);
    return;
  }
}

function onWheel(event) {
  if (event.deltaY < 0) {
    setZoomIn();
  } else {
    setZoomOut();
  }
}

function onClickMinimap(event) {
  event.preventDefault();
  const { camera } = context;
  const step = 5;
  const canvas = document.getElementById("minimap");
  const rect = canvas.getBoundingClientRect();
  const x = (event.clientX - rect.left) * step;
  const y = (event.clientY - rect.top) * step;
  //console.log("camera", camera, "x", x, "y", y)
  if (x !== camera.x && y !== camera.y) {
    context.camera = { x, y };
    drawMap();
  }
}

function setBuildInfo() {
  const time = new Date(
    import.meta.env.VITE_BUILD_TIME * 1000
  ).toLocaleString();
  const version = import.meta.env.VITE_VERSION;
  document.getElementById("build-info").innerHTML =
    "build: " + [version, time].join(" - ");
}

async function init() {
  context.map = await getMap("home");
  const center = Math.ceil(context.map.length / 2);
  context.players = [{ x: center, y: center, current: true, name: "hero" }];
  context.camera = { x: center, y: center };
  function resizeFunc() {
    drawMap(true);
  }
  window.onresize = debounce(resizeFunc, 100);
  setBuildInfo();
  drawMap(true);
}

document.addEventListener("keypress", onKeypress);
document.addEventListener("wheel", onWheel, { passive: true });
document
  .getElementById("minimap")
  .addEventListener("contextmenu", onClickMinimap);
document.getElementById("minimap").addEventListener("click", onClickMinimap);
window.addEventListener("DOMContentLoaded", init);
